import React from "react";
import LayoutHeaderFooter from "../../../components/LayoutHeaderFooter/LayoutHeaderFooter";
import FirstComponentSmall from "../../../components/FirstComponentSmall/FirstComponentSmall";
import "./blogSubpage.scss";
import ReactMarkdown from "react-markdown";
import LayoutComponent from "../../../components/LayoutComponent/LayoutComponent";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const BlogSubpage = (props) => {
  const {
    primary_color,
    secondary_color,
    reading_time,
    title,
    subtitle,
    content,
    image,
    image_alt,
    createdAt,
    recommended,
    SEO,
  } = props.pageContext.content;

  const location_href = props.location.pathname;
  const date = new Date(createdAt).toLocaleDateString("hu-HU");

  return (
    <div className="blogSubpage-wrapper">
      <LayoutHeaderFooter
        header_background_color={secondary_color}
        SEO_title={SEO.title}
        SEO_description={SEO.description}
        SEO_keywords={SEO.keywords}
        SEO_image={SEO.image}
        SEO_location={location_href}
      >
        <FirstComponentSmall
          primary_color={primary_color}
          secondary_color={secondary_color}
          title={title}
          subtitle={subtitle}
          onClick={() => {}}
        />
        <div className="content" style={!image ? { marginTop: "0" } : null}>
          <LayoutComponent>
            <section
              className="content-section"
              style={
                recommended.length === 0 ? { width: "100%", padding: 0 } : null
              }
            >
              {image && (
                <div className="image-wrapper">
                  <img
                    src={`${process.env.GATSBY_CMS_URL}${image.url}`}
                    alt={image_alt}
                    loading="lazy"
                  />
                </div>
              )}
              {date && <p className="date">{date}</p>}
              {reading_time && (
                <p className="reading_time">Olvasási idő: {reading_time}</p>
              )}
              <ReactMarkdown
                children={content}
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
              />
            </section>
            {recommended.length > 0 && (
              <section className="recommended-section">
                <div className="titl">
                  További cikkek, amiket érdemes elolvasni:
                </div>
                {recommended.map((article, index) => {
                  if (index < 2) {
                    return (
                      <a
                        href={`/blog${article.slug}`}
                        className="article"
                        key={index}
                      >
                        {reading_time && (
                          <div className="reading_time">
                            Olvasási idő: {article.reading_time}
                          </div>
                        )}
                        <div className="article_title">{article.title}</div>
                        <div className="article_desc">
                          {article.short_description}
                        </div>
                      </a>
                    );
                  } else {
                    return null;
                  }
                })}
              </section>
            )}
          </LayoutComponent>
        </div>
      </LayoutHeaderFooter>
    </div>
  );
};

export default BlogSubpage;
